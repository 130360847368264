import { AuthenticatedUser,JwtToken } from '../../interfaces';
import * as jwtDecode from 'jwt-decode';
import { APIPaths } from '../constants';

export class TokenHelper {

  public static getAccessToken(): string {
    return localStorage.getItem(APIPaths.accessTokenKey);
  }

  public static setAccessToken(token: string): void {
    return localStorage.setItem(APIPaths.accessTokenKey, token);
  }

  public static setToken(token): void {
    this.setAccessToken(token.access_token);
  }

  public static removeAccessToken(): void {
    return localStorage.removeItem(APIPaths.accessTokenKey);
  }

  public static getBearerToken() {
    const token = localStorage.getItem(APIPaths.accessTokenKey);
    return {
      Authorization: token ? 'Bearer ' + token : null
    };
  }

  public static parseUserToken(token: string): AuthenticatedUser {
    const user: AuthenticatedUser = { email: null, name: null, authenticated: false, isAdmin: false, exp: null };
    try {
      if (token) {
        const decodedToken: JwtToken = jwtDecode(token);

        if (!user.authenticated)
          user.authenticated = true;

        user.email = decodedToken['preferred_username'];
        user.name = decodedToken['name'];
        user.exp = new Date(decodedToken.exp * 1000);
        user.isAdmin = JSON.parse(localStorage.getItem("userStatus"));
      }

    } catch (error) {
      console.log(error);
    }
    return user;
  }

  public static isAdmin() {
    const token = this.getAccessToken();
    let isAdmin: boolean;
    let user: AuthenticatedUser = null;
    user = TokenHelper.parseUserToken(token);
    if (user != null)
      isAdmin = user.isAdmin;
    return isAdmin;
  }

  public static getCurrentUserName() {
    const token = this.getAccessToken();
    let username: string;
    let user: AuthenticatedUser = null;
    user = TokenHelper.parseUserToken(token);
    if (user != null)
      username = user.email;
    return username;
  }


  public static getUserDisplayName() {
    const token = this.getAccessToken();
    let username: string;
    let user: AuthenticatedUser = null;
    user = TokenHelper.parseUserToken(token);
    if (user != null)
      username = user.name;
    return username;
  }


  public static isTokenCurrent(value: string | AuthenticatedUser) {

    let user: AuthenticatedUser = null;

    if (typeof value === 'string') {
      user = TokenHelper.parseUserToken(value);
    } else {
      user = value;
    }
    if (!user)
      return null;
    else if (!user.authenticated)
      return false;
    else
      return user.exp && user.exp > new Date();
  }
}
