// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  baseUrl: 'https://complaints.pgc.edu/',
  adalConfig: {
    tenant: '12b221b3-0464-4223-89e5-888835778b58',
    clientId: '9bf26259-22b5-4137-b900-56c27dcd0df4',
    redirectUri:"http://localhost:4200",
    endpoints: {
      "https://localhost/Api/": "xxx-bae6-4760-b434-xxx"
    },
    navigateToLoginRequestUrl: false,
    cacheLocation: 'localStorage',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
