import { environment } from '../../environments/environment';

export const MessageTypes = {
  error: "Error",
  info: "Info",
  failure: "Failure",
  success: "Success",
  warning: "Warning",
}

var baseUrl = environment.baseUrl + 'api/';
var complaintController = baseUrl + 'complaint/';
var complaintThreadController = baseUrl + 'complaintThread/';
var categoryController = baseUrl + 'category/';
var businessUnitController = baseUrl + 'businessUnit/';
var accountController = baseUrl + 'Account/'
var attachmentController = baseUrl + 'Attachment/';
var clusterController = baseUrl + 'Cluster/'
export const APIPaths = {
  accessTokenKey: 'msal.idtoken',
  ipInfoUrl: 'https://ipinfo.io/json?token=30f8380c121bfb',
  /**************************Login***************************/
  login: accountController + 'Login',
  logout: accountController + 'Logout',

  /********************Business Units************************** */
  getCitiesListForClusters: businessUnitController + 'GetCitiesListForClusters',
  getTopTenCitiesByBU: businessUnitController+'GetTopTenCitiesByBU',
  getAllBUChilds: businessUnitController + 'GetAllCitiesByBusinessUnit',
  getCampusListByCityId: businessUnitController + 'GetCampusListByCityID',

  /**************************Complaint***************************/
  getAllComplaints: complaintController + 'GetAllComplaints',
  getComplaintDetails: complaintController + 'GetComplaintById',

  /**************************Complaint Thread***************************/
  getComplaintThread: complaintThreadController + 'GetComplaintThreads',
  postComplaintThread: complaintThreadController + 'InsertComplaintThreads',
  markThreadRead: complaintThreadController + 'MarkThreadRead',

  /**************************Priority***************************/
  getAllPriorities: categoryController + 'GetAllPriorities',
  setPriority: complaintController + 'SetPriority',
  assignComplaint: complaintController + 'AssignComplaint',
  closeComplaint: complaintController + 'CloseComplaint',

  /********************Attachment*********************************/
  uploadAttachemnt: attachmentController + 'UploadFile',
  downloadAttachemnt: attachmentController + 'DownloadFile',
  deleteAttachemnt: attachmentController + 'DeleteFile',

  /********************Cluster*********************************/
  getAllClusters: clusterController + 'GetAllClusters',
  getSingleCluster: clusterController + 'GetSingleClusterDetail',
  editCluster: clusterController + 'EditClusterDetail'
}

export const ResultMessages = {
  serverError: "Internal Server Error",
  requiredAllField: "Please fill all feilds",
  closeComplaintConfirmation: "Are you sure you want to resolve ",
  fileSizeLimit: "File size can not be more than 1 MB.",
  fileExtension: "File Format not supported",
  fileNotFound: "Sorry, we are unable to download this file for you.",
  forwardToAssignee: "Are you sure you want to forward this message to assignee?",
  forwardToComplainant: "Are you sure you want to forward this message to complainant?"
}

export const DropDownNames = {

}

