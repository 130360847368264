import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { pipe } from '@angular/core/src/render3';
import { finalize } from 'rxjs/operators';
import { Alerts, ResultMessages, TokenHelper } from '../common';
import { AuditTrailService } from '../_services/audit-trail.service';
import { AuditTrail } from '../interfaces';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  loading: boolean;
  constructor(private router: Router, private authService: AuthService, private auditService: AuditTrailService) { }

  ngOnInit() {
    if (this.authService.authenticated) {
      this.router.navigate(['/dashboard'])
    }
  }

  async signIn(): Promise<void> {
    this.loading = true;
    let result = await this.authService.signIn().catch((reason) => {
      this.loading = false;
    });
    if (result) {
      this.auditService.getDeviceAndUserInfo().subscribe(result => {
        const audit: AuditTrail = result;
        audit.userName = TokenHelper.getUserDisplayName();
        this.authService.login(result, audit).pipe(
          finalize(() => {
            this.loading = false;
          }))
          .subscribe(result => {
            if (result && localStorage.getItem('userStatus') != undefined) {
              this.router.navigate(['/dashboard']);
            }
            else
              this.loading = false;
          },
            error => {
              Alerts.showErrorMessage(ResultMessages.serverError);
            })

      })
    }

  }

}
