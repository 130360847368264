import Swal from 'sweetalert2';
import { MessageTypes } from './constants';

export class Alerts {

    public static showErrorMessage(message, title?) {
        return Swal.fire({
            title: title != null ? title : MessageTypes.error,
            text: message,
            type: 'error',
        });
    }

    public static showSuccessMessage(message, title?) {
        return Swal.fire({
            title: title != null ? title : MessageTypes.success,
            text: message,
            type: 'success',
        });
    }

    public static showMessage(message, title?) {
        return Swal.fire({
            title: title != null ? title : '',
            text: message,
        });
    }

    public static showInfoMessage(message, title?) {
        return Swal.fire({
            title: title != null ? title : MessageTypes.info,
            text: message,
            type: 'info',
        });
    }

    public static showWarningMessage(message, showCancelButton, confirmButtonText, cancelButtonText, title?) {
        return Swal.fire({
            title: title != null ? title : MessageTypes.warning,
            text: message,
            type: 'warning',
            showCancelButton: showCancelButton,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
        });
    }

    public static showConfirmationMessage(confirmMessage?, confirmButtonText?, title?) {
        return Swal.fire({
            title: confirmMessage != null ? confirmMessage : 'Are you sure to delete the record?',
            text: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            width:"25%",
            confirmButtonText: confirmButtonText != null ? confirmButtonText : 'Delete'
        });
    }

    public static showInfoMessageWithConfirmation(title?, html?,cancelButton?) {
        return Swal.fire({
            title: title != null ? title : '',
            html: html,
            type: 'info',
            showCancelButton: cancelButton,
            confirmButtonText: 'OK'
        });
    }

    public static showDeletedSuccessfully(message?, title?) {
        return Swal.fire({
            title: title != null ? title : 'Deleted!',
            text: message != null ? message : 'Your record has been deleted.',
            type: 'success'
        });
    }
}
