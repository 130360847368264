import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './api-service';
import { DeviceTypeEnum, APIPaths } from '../common';
import { IpInfo, AuditTrail } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailService extends BaseService {

  constructor(private deviceService: DeviceDetectorService, protected httpClient: HttpClient) {
    super(httpClient);
  }

  getDeviceAndUserInfo() {
    const ipUrl = APIPaths.ipInfoUrl;
    return this.httpClient
      .get(ipUrl).pipe(
        map((response: IpInfo) => {
          const deviceInfo = this.deviceService.getDeviceInfo();
          const model: AuditTrail = {
            userName: null,
            auditType: null,
            ipInfo:{
              location: response.org + ', '  + (response.city ? response.city + ', ' : '') + (response.region ? response.region + ', ' : '')  + response.country,
              ipAddress: response.ip,
              deviceType: this.deviceType(),
              operatingSystem: deviceInfo.os,
              country: response.country,
              city: response.city,
            }
          };
          return model;
        }));
  }

  deviceType() {
    let device: string;
    if (this.deviceService.isMobile()) {
      device = DeviceTypeEnum.Mobile;
    } else if (this.deviceService.isTablet()) {
      device = DeviceTypeEnum.Tablet;
    } else if (this.deviceService.isDesktop()) {
      device = DeviceTypeEnum.Desktop;
    }
    return device;
  }

}
